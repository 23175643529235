import { Component } from '@angular/core';
import {AuthService} from './login/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  
  mostrarMenu: boolean = false;  

  constructor(private authService: AuthService){


  }

  ngOnInit(){
    this.mostrarMenu = this.authService.validarLogin();
    this.authService.mostrarMenuEmitter.subscribe( //subscribe ele emite notificações sempre que ocorre uma mudança no item
      mostrar => this.mostrarMenu = mostrar
    )
    
  }

  onActivate(event) {
    window.scroll(0,0);    
  }
}
