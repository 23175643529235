import { Component, OnInit } from '@angular/core';
import { Usuario } from './usuario';
import { UsuarioService } from './usuario.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-usuario',
  templateUrl: './usuario.component.html',
  styleUrls: ['./usuario.component.scss']
})
export class UsuarioComponent implements OnInit {

  usuario: Usuario = new Usuario;
  error: number = 0;

  public imageSrc: string = 'assets/images/onpoint/camera.png';
  constructor(private usuarioService: UsuarioService,private http: HttpClient, private router: Router) { }

  ngOnInit() {
    this.usuarioService.getUsuario().subscribe(dados => {
      if(dados.status == 1){
        this.usuario = dados.usuario;
      }
    });
   
  }
  onFileSelected(e) {
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    var pattern = /image-*/;
    var reader = new FileReader();
    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }
    reader.onload = this.carregarImg.bind(this);
    reader.readAsDataURL(file);
  }

  carregarImg(e) {
    let reader = e.target;
    this.imageSrc = reader.result;
  }

  updateUsuario(){
    let imagem = this.imageSrc;
    this.usuario.foto = imagem;
    

    if(this.usuario.nova_senha == this.usuario.nova_senha_2){
      this.error = 0;   
      this.usuarioService.updateUsuario(this.usuario).subscribe(dados => {
        console.log(dados);
        alert('Cadastro alterado com sucesso!');
      // this.router.navigate(['/home']);
      });
   
      

    } else{
      this.error = 1;
    }
  }


}
