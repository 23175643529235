import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login';
import { ReaderQRComponent } from './reader-qr/reader-qr.component';
import { HomeComponent } from './home';
import { UsuarioComponent } from './usuario';
import { DisplayComponent,DisplayEditarComponent } from './display';
import { AuthGuard } from './guards/auth.guard';



const routes: Routes = [
  { path: 'reader-qr', component: ReaderQRComponent,canActivate:[AuthGuard]},
  { path: 'login', component: LoginComponent },
  { path: '', redirectTo: '/login', pathMatch: 'full',canActivate:[AuthGuard] },
  { path: 'home', component: HomeComponent,canActivate:[AuthGuard]},
  { path: 'usuario', component: UsuarioComponent,canActivate:[AuthGuard]},
  { path: 'display/:id', component: DisplayComponent,canActivate:[AuthGuard]},
  { path: 'display', component: DisplayComponent,canActivate:[AuthGuard]},
  { path: 'display-editar/:id/:status', component: DisplayEditarComponent,canActivate:[AuthGuard]}
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
