export class Display{
    id: number;
    nome: string;
    cliente: string;
    status_display:number;
    latitude: any;
    longitude: any;
    imagem: any;
    observacao: any;
    tipo_avaria: any;
    abastecimento: Number;
    status_nome: String;
    tipo_manutencao: Number;
    capacidade: Number;
    inoperante: string;
}