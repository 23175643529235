import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Display } from './display';
import { HttpClient } from '@angular/common/http';
import { DisplayService } from '../display.service';

@Component({
  selector: 'app-display-editar',
  templateUrl: './display-editar.component.html',
  styleUrls: ['./display-editar.component.scss']
})
export class DisplayEditarComponent {
  selectedFile: File = null;

  tipoSelected: Number;

  public imageSrc: string = 'assets/images/onpoint/camera.png';
  upload = false;
  display: Display;
  display_atual: Display = new Display();
  tipo_avaria: number = null;
  tipos_manutencao: any = [
    { 'id': 1, 'tipo': 'Testeira danificada' },
    { 'id': 2, 'tipo': 'Laterais danificadas' },
    { 'id': 3, 'tipo': 'Iluminação queimada' },
    { 'id': 4, 'tipo': 'Outras partes danificadas, especifique' },
    { 'id': 5, 'tipo': 'Troca da peça' },
    { 'id': 6, 'tipo': 'Outros, especifique' }
  ];
  constructor(private http: HttpClient, private router: ActivatedRoute, private router_1: Router, private displayService: DisplayService) { }
  

  ngOnInit() {

    this.display = new Display();
    this.display.id = +this.router.snapshot.paramMap.get('id');
    this.display.status_display = +this.router.snapshot.paramMap.get('status');

    this.displayService.getDisplay(this.display.id).subscribe(display => {

      this.display_atual = display;
    });


  }

  alterarTipoManutencao(e) {
    console.log(e);
  }

  onFileSelected(e) {
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    var pattern = /image-*/;
    var reader = new FileReader();
    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }
    reader.onload = this.carregarImg.bind(this);
    reader.readAsDataURL(file);
  }

  carregarImg(e) {
    let reader = e.target;
    this.imageSrc = reader.result;
    this.upload = true;
  }

  salvar() {
    let imagem = this.imageSrc;
    this.display.imagem = imagem;
    console.log(this.display);

    //Se for abastecimento
    if(this.display.status_display == 3 && (this.display.abastecimento < 1 || this.display.abastecimento > this.display_atual.capacidade)){
      alert('Display não comporta mais do que ' + this.display_atual.capacidade + ' produtos.');
      return false;
    }
    //Se for manutenção
    if(this.display.status_display == 2 && this.display.tipo_avaria == 0){
      alert('Informe o tipo de manutenção.');
      return false;
    }

    let startPos;
    var display = this.display;
    var displayService = this.displayService;
    var router = this.router_1;
    let geoSuccess = function (position) {
      startPos = position;
      display.latitude = startPos.coords.latitude;
      display.longitude = startPos.coords.longitude;
      //display.status_display = 1;

      displayService.atualizar(display).subscribe(dados => {
        if (dados.status == 1) {
          let msg = 'Display atualizado com sucesso!';
          if(display.status_display == 3){
            msg = 'Abastecimento registrado com sucesso!';
          }
          alert(msg);
          router.navigate(['/']);
        }
      });

    };

    navigator.geolocation.getCurrentPosition(geoSuccess);


    // alert('Display alterado com sucesso!');
    // this.router_1.navigate(['/display/'+ this.display.id]);
  }




}
