import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Usuario } from './usuario';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  private url = 'https://lab.geeksdesenv.com/onpoint/ws/usuario';

 

constructor(private http: HttpClient, private router: Router) { }

ngOninit(){


}

getUsuario() : any{
let id_usuario:string = localStorage.getItem('id_usuario');

   return this.http.post(this.url+'/get', {
    'id': id_usuario
    });
}

updateUsuario(usuario:Usuario){
  return this.http.post(this.url + '/update' ,usuario);

  
}


}
