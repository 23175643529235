import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Display } from './display/display';

@Injectable({
  providedIn: 'root'
})
export class DisplayService {

  private url = 'https://lab.geeksdesenv.com/onpoint/ws/display/';
  constructor(private http: HttpClient, private router: Router) { }


  getDisplay(id_display: number): any {
    return this.http.post(this.url + 'get', {
      'display': id_display
    });
    
  }

  atualizar(display: Display): any {
    return this.http.post(this.url + 'update', {
      display: display,
      id_usuario: localStorage.getItem('id_usuario')
    });
  }


}
