import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../login';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor( private authService : AuthService, private router : Router) { }

  canActivate(route: ActivatedRouteSnapshot,state: RouterStateSnapshot) : Observable<boolean> | boolean{

if(this.authService.validarLogin()){
    return true;
} 

this.router.navigate(['/login']);
  return false;

  }
}
