import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DisplayService } from '../display.service';
import { Display } from './display';
import { faPlay, faTimesCircle, faTools, faSync } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-display',
  templateUrl: './display.component.html',
  styleUrls: ['./display.component.scss']
})
export class DisplayComponent implements OnInit {

  display: Display;
  faPlay = faPlay;
  faTimesCircle = faTimesCircle;
  faTools = faTools;
  faSync = faSync;
 

  constructor(private route:ActivatedRoute, private displayService: DisplayService, private router: Router) { }

  ngOnInit() {
    this.display = new Display();
    const id = + this.route.snapshot.paramMap.get('id');

    this.displayService.getDisplay(id).subscribe( display => {
     
      if(display.status == 0){
        alert('Display não localizado ou removido.');
        this.router.navigate(['/home']);
      }
      this.display = display;
    });
  }


  ativarDisplay(){
      let startPos;
      var display = this.display;
      var displayService = this.displayService;
      let geoSuccess = function(position) {
        startPos = position;        
        display.latitude = startPos.coords.latitude;
        display.longitude = startPos.coords.longitude;
        display.status_display = + this.route.snapshot.paramMap.get('id');

        displayService.atualizar(display).subscribe( status => {
          console.log(status);
        });

      };

      navigator.geolocation.getCurrentPosition(geoSuccess);
    };
  }


