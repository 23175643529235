import { Component, OnInit } from '@angular/core';
import { AuthService } from './auth.service';
import { Usuario } from '../usuario/usuario';
import { Router } from '@angular/router';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: [
    './login.component.scss'
  ]
})



export class LoginComponent implements OnInit {

  public usuario: Usuario = new Usuario();

  constructor(private authService: AuthService, private router: Router) { }


  ngOnInit() {
    if(this.authService.validarLogin()){
      this.router.navigate(['/home']);
    } else{
      this.router.navigate(['/login']);
    }
  }


  fazerLogin() {
    this.authService.fazerLogin(this.usuario);
  }


}
