import {Component, ViewChild, ViewEncapsulation, OnInit} from '@angular/core';
import {QrScannerComponent} from 'angular2-qrscanner';
import { AuthService } from '../login/auth.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-reader-qr',
  templateUrl: './reader-qr.component.html',
  styleUrls: ['./reader-qr.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ReaderQRComponent implements OnInit {


  constructor(private authService: AuthService, private router: Router) { }

  @ViewChild(QrScannerComponent,{static:true}) qrScannerComponent: QrScannerComponent ;

  ngOnInit() {
    if(!this.authService.validarLogin()){
        this.router.navigate(['/login']);
   
    }
    //this.router.navigate(['/display/4']);


   this.qrScannerComponent.getMediaDevices().then(devices => {
            //console.log(devices);
            const videoDevices: MediaDeviceInfo[] = [];
            for (const device of devices) {
                if (device.kind.toString() === 'videoinput') {
                    videoDevices.push(device);
                }
            }
            if (videoDevices.length > 0){
                let choosenDev;
                for (const dev of videoDevices){
                    if (dev.label.includes('back')){
                        choosenDev = dev;
                        break;
                    }
                }
                if (choosenDev) {
                    this.qrScannerComponent.chooseCamera.next(choosenDev);
                }
                 else {
                    this.qrScannerComponent.chooseCamera.next(videoDevices[0]);
                }
            }
        });
 
        this.qrScannerComponent.capturedQr.subscribe(result => {
            
            this.router.navigate(['display/' + result.toString()]);
        });
  }
}