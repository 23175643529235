import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Usuario } from '../usuario/usuario';
import { Router } from '@angular/router';
import { logWarnings } from 'protractor/built/driverProviders';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private url = 'https://lab.geeksdesenv.com/onpoint/usuario/login';
  private usuarioAutenticado: boolean = false;

  mostrarMenuEmitter = new EventEmitter <boolean>();


  constructor(private http: HttpClient, private router: Router) { }

  getLogin(usuario: string, senha: string): any {
    return this.http.post(this.url, {
      'email': usuario,
      'senha': senha
    });
  }

  fazerLogin(usuario: Usuario) {

    this.getLogin(usuario.email, usuario.senha).subscribe(dados => {
      if (dados.status == 0) {
        alert('erro no login');
        this.mostrarMenuEmitter.emit(false);
        this.usuarioAutenticado = false;

      } else {
        localStorage.setItem('id_usuario', dados.id);
        localStorage.setItem('nome_usuario', dados.nome);
        this.usuarioAutenticado = true;
        this.mostrarMenuEmitter.emit(true); //se o usuario logar recebe true
        this.router.navigate(['/home']);
     
       
      }
    });

  }

  validarLogin() {
    if (localStorage.getItem('id_usuario')) {
      
      return true;
    } else{
      this.usuarioAutenticado = false;
    return false;
  }
}

  logoff() {
    localStorage.removeItem('id_usuario');
    localStorage.removeItem('nome_usuario');

    
    this.mostrarMenuEmitter.emit(false);
  }

}
