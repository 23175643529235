import { Component, OnInit } from '@angular/core';
import {AuthService} from '../login/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(private authService: AuthService,private router: Router) { }

  ngOnInit() {

      if(!this.authService.validarLogin()){
        this.router.navigate(['/login']);
      } else{
        this.router.navigate(['/home']);
      }
   
    }
  

  logoff() {
    this.authService.logoff();
    this.router.navigate(['/login']);
  }

}
