export class Usuario{
    nome: any;
    email: any;
    senha: any;
    telefone: any;
    endereco: any;
    foto: any;
    nova_senha: any;
    nova_senha_2: any;
    senha_atual: any;

}