import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgQrScannerModule } from 'angular2-qrscanner';
import { HttpClientModule } from '@angular/common/http'; 
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxUiLoaderModule, NgxUiLoaderHttpModule } from  'ngx-ui-loader';
import { NgSelectModule } from '@ng-select/ng-select';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginModule } from './login';
import { AuthService } from './login/auth.service';
import { MenuPrincipalComponent } from './inc/menu-principal/menu-principal.component';
import { MenuRodapeComponent } from './inc/menu-rodape/menu-rodape.component';
import { ReaderQRComponent } from './reader-qr/reader-qr.component';
import { HomeComponent } from './home/home.component';
import { UsuarioComponent } from './usuario/usuario.component';
import { DisplayComponent } from './display/display.component';
import { AuthGuard } from './guards/auth.guard';
import { DisplayEditarComponent } from './display/display-editar.component';





@NgModule({
  declarations: [
    AppComponent,
    MenuPrincipalComponent,
    MenuRodapeComponent,
    ReaderQRComponent,
    HomeComponent,
    UsuarioComponent,
    DisplayComponent,
    DisplayEditarComponent

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    LoginModule,
    NgQrScannerModule,
    HttpClientModule,
    FormsModule,
    FontAwesomeModule,
    NgxUiLoaderModule,
    NgxUiLoaderHttpModule,
    NgxUiLoaderHttpModule.forRoot({ showForeground: true }),
    NgSelectModule
  ],
  providers: [AuthService, AuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule {
  
 }
